import React from 'react';
import { PageProps } from 'gatsby';
import Footer from '../components/footer';
import Layout from '../components/layout';
import Navigation from '../components/navigation';
import SEO from '../components/seo';
import SignUpForm from '../components/sign-up-form';

type LocationState = { email?: string } | undefined;

export default function SignUpPage({ location }: PageProps<object, object, LocationState>) {
  return (
    <Layout>
      <SEO title="Sign Up" />
      <Navigation />
      <SignUpForm prefilledEmail={location.state?.email} />
      <Footer />
    </Layout>
  );
}
