import { Spinner } from '@chakra-ui/core';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers';
import config from '@lcdev/app-config';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Container, Section } from './global';

type Props = {
  prefilledEmail?: string;
};

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required('Email is required')
      .email('Email is not valid'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password is too short - should be 8 chars minimum'),
  })
  .defined();

type FormData = yup.InferType<typeof schema>;

export default function SignUpForm({ prefilledEmail }: Props) {
  const [isRegistered, setIsRegistered] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  return (
    <StyledSection>
      <StyledContainer>
        <FormContainer>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {!isRegistered ? (
                <>
                  <Title>Sign up for early access</Title>
                  <Header>Create your account</Header>
                  <Form
                    onSubmit={handleSubmit(async ({ email, password }) => {
                      setIsLoading(true);

                      const response = await fetch(`${config.apiUrl}/api/user/register`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          email,
                          password,
                        }),
                      });

                      setIsLoading(false);

                      // Check if this email already exists
                      if (response.status === 409) {
                        setIsDuplicate(true);
                      }

                      setIsRegistered(true);
                    })}
                  >
                    <Input
                      name="email"
                      ref={register}
                      placeholder="Email"
                      defaultValue={prefilledEmail}
                      autoFocus={!prefilledEmail}
                    />
                    <FormError>{errors.email?.message}</FormError>
                    <Input
                      name="password"
                      ref={register}
                      placeholder="Password"
                      type="password"
                      autoFocus={!!prefilledEmail}
                    />
                    <FormError>{errors.password?.message}</FormError>
                    <Button>Sign Up</Button>
                  </Form>
                  <Subtitle>No credit card required.</Subtitle>
                </>
              ) : !isDuplicate ? (
                <>
                  <Title>Received!</Title>
                  <Header>
                    You will receive an email shortly confirming your sign up. When it is your turn
                    to try the product, you will receive another email with further instructions.
                    <br />
                    <br />
                    We are excited about the future of TakeNote! Thanks again for your interest.
                  </Header>
                  <Button type="button" onClick={() => navigate('/')} style={{ width: 200 }}>
                    Go Home
                  </Button>
                </>
              ) : (
                <>
                  <Title>You&apos;re already signed up!</Title>
                  <Header>
                    It looks like you have already signed up! You should have received an email
                    confirming your sign up. If you haven&apos;t received anything, please contact{' '}
                    <a href="mailto:hello@gotakenote.com">hello@gotakenote.com</a> and we&apos;ll be
                    happy to help resolve it.
                    <br />
                    <br />
                    When it is your turn to try the product, you will receive another email with
                    further instructions.
                    <br />
                    <br />
                    We are excited about the future of TakeNote! Thanks again for your interest.
                  </Header>
                  <Button type="button" onClick={() => navigate('/')} style={{ width: 200 }}>
                    Go Home
                  </Button>
                </>
              )}
            </>
          )}
        </FormContainer>
      </StyledContainer>
    </StyledSection>
  );
}

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 60px 0;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  background: ${props => props.theme.color.white.regular};
  border-radius: 10px;
`;

const Title = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`;

const Header = styled.h5`
  margin: 0 auto 32px;
  text-align: center;
  ${props => props.theme.fontSize.regular};
`;

const Form = styled.form`
  width: 400px;
`;

const FormError = styled.p`
  color: #ff0000;
  margin-top: 0px;
  margin-bottom: 25px;
`;

const Input = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 10px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`;

const Button = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  width: 100%;
  margin-bottom: 20px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
  }
`;

const Subtitle = styled.span`
  ${props => props.theme.fontSize.xxsmall}
  padding-top: 16px;
  font-size: 14px;
  color: ${props => props.theme.color.primary};
`;
